var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('ModalApproveBulky',{attrs:{"data-testid":"adjustment-topup-saldo-approving-topup-saldo__modal-approve-bulky","data":_vm.dataBulkApprove,"visible":_vm.showModalApprove,"type":"poin","isLoading":_vm.controller.isLoading},on:{"check-again":function($event){return _vm.handleCheckAgain()},"approve":function($event){return _vm.handleApprove()}}}),_c('ModalReject',{attrs:{"data-testid":"adjustment-topup-saldo-approving-topup-saldo__modal-reject-bulky","visible":_vm.showModalReject,"title":_vm.MODAL_REJECT_REQUEST_TOPUP.title_bulk,"subtitle":_vm.MODAL_REJECT_REQUEST_TOPUP.subtitle,"message":_vm.MODAL_REJECT_REQUEST_TOPUP.message,"hint":_vm.MODAL_REJECT_REQUEST_TOPUP.hint,"isDisabled":_vm.controller.isLoading},on:{"close":function () {
        _vm.showModalReject = false
      },"reject":_vm.handleReject}}),_c('Modal',{attrs:{"data-testid":"adjustment-topup-saldo-approving-topup-saldo__modal-success","visible":_vm.modalSuccess.visible,"type":"success","modalContainer":"modal-container-md","title":_vm.modalSuccess.title,"body":_vm.modalSuccess.subtitle,"textButton":"Okay"},on:{"change":function($event){return _vm.resetModalSuccess()}}}),_c('div',{staticClass:"grid grid-cols-10 gap-x-5 justify-between"},[_c('div',{staticClass:"col-span-8"},[_c('h2',{staticClass:"text-sm font-semibold text-bgMatterhorn mb-2"},[_vm._v("Search")]),_c('TextInput',{attrs:{"data-testid":"adjustment-topup-saldo-approving-topup-saldo__input-search","placeholder":"Masukkan Nomor Handphone","type":"search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
            // fetch api when integration
            _vm.fetchListAdjustmentParcelPoin(true)
          })($event)},"input":function (e) {
            _vm.onlyInputPhone(e)
          }},model:{value:(_vm.parameters.phoneNumber),callback:function ($$v) {_vm.$set(_vm.parameters, "phoneNumber", $$v)},expression:"parameters.phoneNumber"}})],1),_c('div',{staticClass:"col-span-2"},[_c('h2',{staticClass:"text-sm font-semibold text-bgMatterhorn mb-2"},[_vm._v("Urutkan")]),_c('DropdownSelect',{attrs:{"allowEmpty":false,"clearOnSelect":false,"searchable":false,"options":_vm.sortOptions},on:{"input":function () {
            // fetch api when integration
            _vm.fetchListAdjustmentParcelPoin(true)
          }},model:{value:(_vm.parameters.sortBy),callback:function ($$v) {_vm.$set(_vm.parameters, "sortBy", $$v)},expression:"parameters.sortBy"}})],1)]),_c('div',{staticClass:"flex items-center justify-between mt-6"},[_vm._m(0),_c('div',{staticClass:"flex gap-x-3"},[_c('Button',{staticClass:"py-2.5 px-10 text-xs font-semibold",attrs:{"data-testid":"adjustment-topup-saldo-approving-topup-saldo__button-reject","customPadding":true,"disabled":!_vm.isSuperAdmin || _vm.dataChecked.length === 0,"buttonStyle":'outline'},on:{"click":function($event){return _vm.rejectBulk()}}},[_vm._v("Reject")]),_c('Button',{staticClass:"py-2.5 px-10 text-xs font-semibold",attrs:{"data-testid":"adjustment-parcel-poin-approving-parcel-poin__button-approve","customPadding":true,"disabled":!_vm.isSuperAdmin || _vm.dataChecked.length === 0},on:{"click":function($event){return _vm.approveBulk()}}},[_vm._v("Approve")])],1)]),_c('div',{staticClass:"mt-6"},[(_vm.dataApprovingPoin.length > 0)?_c('DataTableV2',{attrs:{"isLoading":_vm.controller.isLoading,"headers":_vm.headers,"dataItems":_vm.dataApprovingPoin},scopedSlots:_vm._u([{key:"header-0",fn:function(){return [_c('div',{staticClass:"flex gap-x-5"},[_c('span',{class:("" + (_vm.isSuperAdmin ? 'cursor-pointer' : 'cursor-not-allowed')),attrs:{"data-testid":"adjustment-parcel-poin-approving-parcel-poin__checkbox-all"},on:{"click":function($event){return _vm.checkListAll()}}},[(
                _vm.dataChecked.length === _vm.dataApprovingPoin.length && _vm.dataChecked.length > 0
              )?_c('CheckBoxCheckedIcon',{attrs:{"width":"20","fill":"#B82025"}}):_c('CheckBoxUncheckedIcon',{attrs:{"fill":("" + (_vm.isSuperAdmin ? '#fff' : '#e2e8f0'))}})],1)])]},proxy:true},{key:"0",fn:function(ref){
              var data = ref.data;
              var index = ref.index;
return [_c('div',{class:("flex gap-x-5 cursor-pointer " + (_vm.isSuperAdmin ? 'cursor-pointer' : 'cursor-not-allowed'))},[_c('span',{attrs:{"data-testid":("adjustment-parcel-poin-approving-parcel-poin__checkbox-one-" + index)},on:{"click":function($event){_vm.checkListOne(Number(data.value))}}},[(_vm.dataChecked.includes(Number(data.value)))?_c('CheckBoxCheckedIcon',{attrs:{"width":"20","fill":"#B82025"}}):_c('CheckBoxUncheckedIcon',{attrs:{"fill":("" + (_vm.isSuperAdmin ? '#fff' : '#e2e8f0'))}})],1)])]}},{key:"6",fn:function(ref){
              var data = ref.data;
              var index = ref.index;
return [_c('Button',{staticClass:"py-2.5 px-10 text-xs font-semibold break-normal",attrs:{"data-testid":("adjustment-parcel-poin-approving-parcel-poin__button-need-approval-" + index),"customPadding":"","disabled":!_vm.isSuperAdmin},on:{"click":function($event){return _vm.$router.push({
              name: 'DetailAdjustmentParcelPoinApprovingPoinPage',
              params: { id: data.value },
            })}}},[_vm._v(" Need Approval ")])]}}],null,false,3323001170)}):_c('EmptyState',{attrs:{"type":"proof-delivery","text":"Data Not Found","description":"Approving Parcel Poin data will appear on this page","customClass":"mt-24","classText":"mt-6 text-sm","classDesc":"mt-2 text-sm text-secondaryText"}})],1),_c('PaginationNav',{attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem},on:{"input":function () {
        // call function that to search
        _vm.fetchListAdjustmentParcelPoin(false)
      },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
        // call function that to search
        _vm.fetchListAdjustmentParcelPoin(false)
      })($event)}},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-base font-semibold text-bgMatterhorn"},[_c('h2',[_vm._v("All List Approving Parcel Poin")])])}]

export { render, staticRenderFns }



























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import {
  APPROVING_PARCEL_POIN_PAGINATION,
  EnumTypeBalanceStatus,
  EventBusConstants,
  MODAL_REJECT_REQUEST_POIN,
  MODAL_SUCCESS_APPROVE_POIN,
  MODAL_SUCCESS_REJECT_POIN,
  Utils,
} from '@/app/infrastructures/misc'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import controller from '@/app/ui/controllers/AdjustmentParcelPoinController'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import ModalApproveBulky from '../components/ModalApproveBulky/index.vue'
import ModalReject from '../components/AdjustmentModals/ModalReject/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Dictionary } from 'vue-router/types/router'

export interface IApprovingPoinParams {
  sortBy: string
  phoneNumber: string
  page: number | string
  perPage: string | number
  status: string
}

interface dataBulkApproveInterface { 
  customerId: number; 
  phoneNumber: string; 
  amount: string | number; 
  note: string; 
}

export interface modalSuccessInterface {
  visible: boolean,
  title: string,
  subtitle: string,
}

@Component({
  components: {
    LoadingOverlay,
    TextInput,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    Button,
    EmptyState,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    ModalApproveBulky,
    ModalReject,
    Modal,
  },
})
export default class ApprovingPoin extends Vue {
  isSuperAdmin = Utils.isSuperAdmin()
  sortOptions: Array<Record<string, string>> = [
    { label: 'Terbaru', value: 'NEWEST' },
    { label: 'Terlama', value: 'OLDEST' },
  ]
  parameters = {
    sortBy: this.sortOptions[0],
    page: 1,
    perPage: APPROVING_PARCEL_POIN_PAGINATION,
    status: 'REQUESTED',
    phoneNumber: '',
  }
  headers: Array<HeaderObject> = [
    {
      title: '',
      customStyle: { maxWidth: '50px', minWidth: '50px' },
    },
    {
      title: 'Customer ID',
      customStyle: { maxWidth: '150px', minWidth: '150px' },
    },
    {
      title: 'Phone Number',
      customStyle: { maxWidth: '150px', minWidth: '150px' },
    },
    {
      title: 'Total Poin',
      customStyle: { maxWidth: '150px', minWidth: '150px' },
    },
    {
      title: 'Request Date',
      customStyle: { maxWidth: '200px', minWidth: '200px' },
    },
    {
      title: 'Requestor',
      customStyle: { maxWidth: '300px', minWidth: '300px' },
    },
    {
      title: 'Action',
      customStyle: { maxWidth: '200px', minWidth: '200px' },
    },
  ]
  controller = controller
  dataChecked: Array<number> = []
  showModalReject = false
  showModalApprove = false
  dataBulkApprove: Array< dataBulkApproveInterface | undefined> = []
  MODAL_REJECT_REQUEST_TOPUP = MODAL_REJECT_REQUEST_POIN
  modalSuccess: modalSuccessInterface = {
    title: '',
    subtitle: '',
    visible: false,
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        phoneNumber: decodeURIComponent(queries.phoneNumber || ''),
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: APPROVING_PARCEL_POIN_PAGINATION,
        status: 'REQUESTED',
      }
    }

    // fetch api
    this.fetchListAdjustmentParcelPoin()
  }

  get params(): IApprovingPoinParams {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      phoneNumber: encodeURIComponent(this.parameters.phoneNumber),
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router
      .replace({
        query: { ...val },
      })
      .catch(() => false)
  }

  private onlyInputPhone(e: string): void {
    this.parameters.phoneNumber = Utils.phoneFormat(e)
  }

  get dataApprovingPoin(): Array<DataObject[]> {
    if (this.controller.listHistoryLogPoinUser.length > 0) {
      return this.controller.listHistoryLogPoinUser.map(item => {
        return [
          {
            value: item.id,
            customStyle: { maxWidth: '50px', minWidth: '50px' },
          },
          {
            value: item.customerId || '',
            customStyle: { maxWidth: '150px', minWidth: '150px' },
          },
          {
            value: item.phoneNumber ? Utils.countryIndonesiaPhoneNumber(item.phoneNumber, true) : '-',
            customStyle: { maxWidth: '150px', minWidth: '150px' },
          },
          {
            value: Utils.currencyDigit(Number(item.delta)),
            customStyle: { maxWidth: '150px', minWidth: '150px' },
          },
          {
            value: item.createdAt ? Utils.formatTimeZone(
              Utils.formatDate(item.createdAt, 'DD MMM YYYY, HH:mm Z')
            ) : '-',
            customStyle: { maxWidth: '200px', minWidth: '200px' },
          },
          {
            value: item.requester,
            customStyle: { maxWidth: '300px', minWidth: '300px' },
          },
          {
            value: `${item.customerId}-${item.id}` || '',
            customStyle: { maxWidth: '200px', minWidth: '200px' },
          },
        ]
      })
    }
    return []
  }

  @Watch('dataChecked')
  onDataCheckedChange(): void {
    const dataFilter =  this.controller.listHistoryLogPoinUser?.filter((item) => this.dataChecked.includes(Number(item.id)))
    this.dataBulkApprove = dataFilter.map((item, idx) => {
      if (item.id === this.dataChecked[idx]) {
        return {
          customerId: item.customerId || 0,
          phoneNumber: item.phoneNumber || '-',
          amount: Utils.currencyDigit(Number(item.delta)) || '-',
          note: item.note || '-',
          transaction: Number(item.delta) > 0 ? 'Penambahan' : 'Pengurangan'
        }
      }
    })
  }

  private checkListOne(id: number): void {
    if (!this.isSuperAdmin) return
    if (this.dataChecked.includes(id)) {
      this.dataChecked = this.dataChecked.filter(item => {
        return item !== id
      })
      return
    }
    this.dataChecked.push(id)
  }

  private checkListAll(): void {
    if (!this.isSuperAdmin) return
    if (this.dataChecked.length === this.dataApprovingPoin.length) {
      this.dataChecked = []
      return
    } 
    this.dataApprovingPoin.forEach(item => {
      if (!this.dataChecked.includes(Number(item[0].value))) {
        this.dataChecked.push(Number(item[0].value))
      }
    })
  }

  private rejectBulk(): void {
    if (!this.isSuperAdmin) return
    this.showModalReject = true
  }

  private approveBulk(): void {
    if (!this.isSuperAdmin) return
    this.showModalApprove = true
  }

  private handleCheckAgain(): void {
    this.showModalApprove = false
  }

  private handleReject(note: string): void {
    if (!this.isSuperAdmin) return
    // TODO: Integration Reject Bulky Parcel Poin
    controller.approveRejectBulkyParcelPoin({
      pointStatus: EnumTypeBalanceStatus.REJECTED,
      loyaltyPointHistoryIds: this.dataChecked,
      reasonNote: note
    })
  }

  private handleApprove(): void {
    if (!this.isSuperAdmin) return
    // TODO: Integration Approve Bulky Parcel Poin
    controller.approveRejectBulkyParcelPoin({
      pointStatus: EnumTypeBalanceStatus.APPROVED,
      loyaltyPointHistoryIds: this.dataChecked,
      reasonNote: ''
    })
  }

  private resetModalSuccess(): void {
    this.modalSuccess = {
      title: '',
      subtitle: '',
      visible: false
    }
  }

  @Watch('controller.statusApproveRejectBulkyPoin', { deep: true })
  onStatusApproveRejectBulkyPoin(status: string): void {
    if (status !== '') {
      switch (status) {
        case EventBusConstants.APPROVE_BULKY_PARCEL_POIN_SUCCESS:
          this.showModalApprove = false
          this.modalSuccess = {
            visible: true,
            title: MODAL_SUCCESS_APPROVE_POIN.title,
            subtitle: MODAL_SUCCESS_APPROVE_POIN.subtitle,
          }
          this.fetchListAdjustmentParcelPoin(true)
          break;
        case EventBusConstants.REJECT_BULKY_PARCEL_POIN_SUCCESS:
          this.showModalReject = false
          this.modalSuccess = {
            visible: true,
            title: MODAL_SUCCESS_REJECT_POIN.title,
            subtitle: MODAL_SUCCESS_REJECT_POIN.subtitle
          }
          this.fetchListAdjustmentParcelPoin(true)
          break;
        case EventBusConstants.APPROVE_BULKY_PARCEL_POIN_FAILED:
          this.handleCheckAgain()
          break;
        case EventBusConstants.REJECT_BULKY_PARCEL_POIN_FAILED:
          this.showModalReject = false
          break;
      }
      controller.setStatusApproveRejectBulkyPoin('')
    }
  }

  private fetchListAdjustmentParcelPoin(resetPage?: boolean): void {
    this.dataChecked = []
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getHistoryLogPoinUser(this.params)
  }

  beforeDestroy(): void {
    controller.setStatusApproveRejectBulkyPoin('')
  }
}
